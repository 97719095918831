<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A compound has the empirical formula
        <stemble-latex :content="x" />
        What mass of carbon would there be in a
        <number-value :value="mass" unit="\text{g}" />
        sample of this compound?
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{Mass: }$"
        append-text="$\text{g}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131Task5',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    x() {
      let x;
      let versionNumber = this.taskState.getValueBySymbol('x')?.content?.value ?? 2;
      if (versionNumber === 1) {
        x = '$\\ce{CH4}.$';
      } else if (versionNumber === 2) {
        x = '$\\ce{C2H6}.$';
      } else if (versionNumber === 3) {
        x = '$\\ce{C3H8}.$';
      } else if (versionNumber === 4) {
        x = '$\\ce{C4H10}.$';
      } else if (versionNumber === 5) {
        x = '$\\ce{C5H12}.$';
      } else if (versionNumber === 6) {
        x = '$\\ce{C6H14}.$';
      }
      return x;
    },
  },
};
</script>
